import React, { useEffect, useState } from 'react';
import WalletConnect from './components/WalletConnect';
import { ethers } from 'ethers';
import './App.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import abi from './abi/abi.json'
import bytecode from './abi/TokenByteCode.js'

function App() {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [copied, setCopied] = useState(false);

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [factory, setFactory] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [deployedCA, setDeployedCA] = useState(null);

  const [showError, setShowError] = useState(false)


  //基本資訊
  const [name, setName] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [supply, setSupply] = useState(0);
  // const [decimal, setDecimal] = useState(0);


  const handleAccountChange = (value) => {
    setDefaultAccount(value);
  }
  const updateEthers = async () => {
    try {
      let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);

      let tempSigner = tempProvider.getSigner();
      setSigner(tempSigner);

      let tempFactory = new ethers.ContractFactory(abi, bytecode, tempSigner);
      setFactory(tempFactory);
    } catch (error) {
      console.log(error)
    }
  }

  const checkParams = () => {
    if (name == null || symbol == null || supply == null || supply < 1) {
      alert("Please Fill In The Valid Parameters")
      return;
    }
    deployContract()
    setIsLoading(true)
    handleOpenModal()
  }

  const getLog = async (value) => {
    console.log("GETTING LOG");
    const transaction = await provider.getTransactionReceipt(value);
    if (transaction == null)
      setTimeout(() => getLog(value), 3000)
    else {
      const topic = "0x8ffcdc15a283d706d38281f500270d8b5a656918f555de0913d7455e3e6bc1bf";
      console.log(transaction);
      transaction.logs.map((data, index) => {
        if (data.topics[0] != topic) return;
        setDeployedCA(transaction.logs[index].address)
        setIsLoading(false)
      })
    }
  }

  const deployFee = ethers.utils.parseEther("5")

  const deployContract = async () => {
    console.log("Deploying Contract");

    const args = [name, symbol, supply, 9];
    try {
      console.log(factory)
      let result = await factory.deploy(...args, { value: deployFee })
      console.log(result);
      getLog(result.deployTransaction.hash);
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setShowError(true)
    }
  }

  useEffect(() => {
    updateEthers()
  }, [defaultAccount])

  const texts = [
    {
      title: 'Name',
      placeholder: 'Contract Name',
      function: (e) => setName(e.target.value),
      type: 'text',
    },
    {
      title: 'Symbol',
      placeholder: 'Contract Symbol',
      function: (e) => setSymbol(e.target.value),
      type: 'text',
    },
    {
      title: 'Supply',
      placeholder: 'Contract Supply',
      function: (e) => setSupply(e.target.value),
      type: 'number',
    },
  ]

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    if (isLoading) return;
    setShowModal(false);
    setShowError(false);
  };

  const copyCA = (value) => {
    if (value == null) {
      alert("No Contract")
      return;
    }
    try {
      setCopied(true)
      alert(`CA : ${value} copied`)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App" style={{
      width: '100vw', height: '100vh',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <div style={{
        padding: '30px',
        border: '1px solid lightblue',
        borderRadius: '20px'
      }}>
        <div className='WalletBtnContainer' style={{
          // display: 'flex', flexDirection: 'row',
          position: 'fixed', top: '20px', right: '20px'
        }}>
          <WalletConnect defaultAccountChange={handleAccountChange} />
        </div>
        <div className='ContentContainer' style={{
          width: '80vw',
          height: '60vh',
          borderRadius: '20px',
          display: 'flex', flexDirection: 'column',
          alignItems: 'center', justifyContent: 'center'
        }}>
          <h3>
            0 Tax Token Creator <br />
            <span style={{ color: 'red' }}>
              Fee : 5 EOS<br/>
              (The Fees More Than 5 Are The Gas Fees)
              {/* (Free Before The Dev Gets Up) */}
            </span>
          </h3>
          <div style={{
            color: 'deepskyblue', fontSize: '30px', fontWeight: 'bold',
          }}
          >
            {
              texts.map((text, index) => {
                return (

                  <div className='row'
                    style={{
                      display: 'flex', flexDirection: 'row',
                      paddingTop: '5vh',
                      justifyContent: 'space-between'
                    }}
                    key={index}
                  >
                    <span>
                      {text.title}
                    </span>
                    <input placeholder={text.placeholder}
                      style={{
                        borderRadius: '10px', height: '40px',
                        marginLeft: '20px', paddingLeft: '20px',
                        border: '2px solid lightblue',
                        outlineColor: 'red',
                      }}
                      onChange={text.function}
                      type={text.type}
                    />
                  </div>
                )
              })
            }
          </div>

          <button
            onClick={() => {
              checkParams()
            }}
            style={{
              marginTop: '5vh',
            }}
          >
            Deploy 0 Tax Contract
          </button>
        </div>
      </div>
      {
        showModal &&
        (<Modal
          onClose={handleCloseModal}
          loading={isLoading}
          loadingText="Deploying..."
          showError={showError}
        >
          <h2>Contract Address : </h2>
          <p style={{ fontSize: '12px' }}>{deployedCA}</p>
          <CopyToClipboard
            text={deployedCA}
            onCopy={() => copyCA(deployedCA)}
          >
            <button style={buttonStyle} onClick={() => setIsLoading(false)}>
              Copy CA
            </button>
          </CopyToClipboard>
        </Modal>)
      }
    </div>
  );
}
const modalStyle = {
  position: "fixed",
  zIndex: 1,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.4)"
};

const modalContentStyle = {
  borderRadius: '40px',
  backgroundColor: "#fefefe",
  margin: "20vh auto",
  padding: "20px",
  border: "1px solid #888",
  width: "80%",
  maxWidth: "600px",
  height: '60vh',
};

const closeStyle = {
  color: "#aaa",
  float: "right",
  fontSize: "28px",
  fontWeight: "bold",
  position: "relative",
  zIndex: "2",
  cursor: 'pointer',
};

const buttonStyle = {
  position: "relative",
  zIndex: "2",
}

export const Modal = (props) => {
  const errorMessage = "Failed to deploy, you need to confirm the transaction";
  const { onClose, onCloseLoading, loading, loadingText, showError, children } = props;
  console.log(loading);
  return (
    <div
      style={modalStyle}
    >
      {
        loading &&
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">{loadingText}</p>
        </div>
      }
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={onClose}>&times;</span>
        {children}
        {
          showError &&
          <p>{errorMessage}</p>
        }
      </div>
    </div>
  );
};

export const ListModal = (props) => {
  const { onClose, children } = props;
  return (
    <div
      style={modalStyle}
    >
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};



export default App;
